import { getBlogUrl, toPlainText } from "../lib/helpers";
import { Link } from "gatsby";
import PortableText from "./portableText";
import React from "react";
import { format } from "date-fns";
import SanityImage from "gatsby-plugin-sanity-image";
import LinesEllipsis from "react-lines-ellipsis";

function BlogPostPreview(props) {
  return (
    <div className="col-md-3 mb-5">
      <div className="card ">
        <Link
          to={getBlogUrl(props.publishedAt, props.slug.current)}
          className="blog-image-container"
        >
          {props.mainImage && props.mainImage.asset && (
            <SanityImage
              {...props.mainImage}
              height={300}
              style={{ height: "100%", width: "100%", objectFit: "cover" }}
              className="img-fluid"
              loading="eager"
            />
          )}
        </Link>
        <div className="card-body">
          <div className="badges mb-3">
            {props.categories && props.categories.length > 0 && (
              <Link
                to={`/category/${props.categories[0].slug.current}`}
                key={props.categories[0]._id}
                className="blog-badges"
              >
                {props.categories[0].title}
              </Link>
            )}
          </div>

          <h3 className="card-title">
            <a href={getBlogUrl(props.publishedAt, props.slug.current)}>
              <LinesEllipsis
                text={props.title}
                maxLine="2"
                ellipsis="..."
                trimRight
                basedOn="letters"
              />
            </a>
          </h3>
          <p className="card-text">
            {props._rawExcerpt && (
              <div>
                <LinesEllipsis
                  text={toPlainText(props._rawExcerpt)}
                  maxLine="3"
                  ellipsis="..."
                  trimRight
                  basedOn="letters"
                />
              </div>
            )}
          </p>
          <p className="card-text d-flex justify-content-between">
            <small className="text-muted">
              {format(new Date(props.publishedAt), "MMM Mo, yyyy")}
            </small>
            {/* <span className="blog-link">
                <Link
                  className="black-text-link"
                  to={getBlogUrl(props.publishedAt, props.slug.current)}
                >
                  Read more
                </Link>
              </span> */}
          </p>
        </div>
      </div>
    </div>
  );
}

export default BlogPostPreview;

{
  /* <Link to={getBlogUrl(props.publishedAt, props.slug.current)}>
  <div>
    {props.mainImage && props.mainImage.asset && (
      <img
        src={imageUrlFor(buildImageObj(props.mainImage))
          .width(600)
          .height(Math.floor((9 / 16) * 600))
          .auto("format")
          .url()}
        alt={props.mainImage.alt}
      />
    )}
  </div>
  <div>
    <h3>{props.title}</h3>
    {props._rawExcerpt && (
      <div>
        <PortableText blocks={props._rawExcerpt} />
      </div>
    )}
    <div>{format(new Date(props.publishedAt), "MMMM Mo, yyyy")}</div>
  </div>
</Link>;
 */
}
