import React, { useState } from "react";
import { graphql, Link } from "gatsby";
import { format } from "date-fns";
import SanityImage from "gatsby-plugin-sanity-image";
import {
  filterOutDocsPublishedInTheFuture,
  filterOutDocsWithoutSlugs,
  filterOutDocs,
  mapEdgesToNodes,
  getBlogUrl,
  buildImageObj,
} from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import BlogPostPreview from "../components/blog-post-preview";
import GraphQLErrorList from "../components/graphql-error-list";
import PortableText from "../components/portableText";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import { heroBg, sliceIntoChunks } from "../lib/helpers";

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query BlogPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
      editorPickBlog {
        id
        publishedAt
        mainImage {
          ...ImageWithPreview
          alt
        }
        title
        _rawExcerpt
        slug {
          current
        }
      }
      footerBlog {
        id
        publishedAt
        mainImage {
          ...ImageWithPreview
          alt
        }
        title
        _rawExcerpt
        slug {
          current
        }
      }
    }
    page: sanitySitePage(slug: { current: { eq: "blog" } }) {
      id
      mainImage {
        ...SanityImage
        alt
      }
      cardImage {
        ...SanityImage
        alt
      }
      title
      description
      slug {
        current
      }
    }
    posts: allSanityPost(
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            ...ImageWithPreview
            alt
          }
          categories {
            _id
            title
            slug {
              current
            }
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
  }
`;

const BlogPage = (props) => {
  const { location, data, errors } = props;
  const { page = {}, site } = data;
  const [currentIndex, setCurrentIndex] = useState(0);

  const loadMoreAction = (e) => {
    e.preventDefault();
    setCurrentIndex(currentIndex + 1);
  };

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  let postNodes = (data || {}).posts
    ? mapEdgesToNodes(data.posts)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutDocsPublishedInTheFuture)
    : [];

  postNodes = sliceIntoChunks(postNodes, 8);

  let currentPostData = [];
  for (let i = 0; i < postNodes.length; i++) {
    currentPostData = [...currentPostData, ...postNodes[i]];
    if (i >= currentIndex) {
      break;
    }
  }

  return (
    <Layout>
      <SEO {...page} location={location} />

      <div className="main">
        <div
          className="heroBanner-innerPage blog-banner"
          style={heroBg(page.mainImage)}
        >
          <section className="banner-caption  d-flex">
            <div className="container d-flex align-content-center flex-wrap">
              <div className="banner-caption_inner  ">
                <h1>{page.title} </h1>
                <p className="paragraph-large breadcrumb ">
                  <Link to="/">Home</Link>{" "}
                  <span className="icon-arrow_forward"></span>
                  {page.title}
                </p>
              </div>
            </div>
          </section>
        </div>

        {site?.editorPickBlog && (
          <section className="latest-post-section blog-post-section pt-5 pb-5">
            <div className="container">
              <div className="heading-n-description ">
                <h2>Editor’s Pick</h2>
              </div>

              <div className="card mb-3">
                <div className="row g-0">
                  <div className="col-lg-6  col-md-7 order-md-2 text-md-end">
                    <SanityImage
                      {...site.editorPickBlog.mainImage}
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "cover",
                      }}
                      className="img-fluid ps-lg-5"
                      loading="eager"
                    />
                  </div>
                  <div className="col-lg-6 col-md-5 order-md-1 d-flex align-items-center">
                    <div className="card-body">
                      <h3 className="card-title">
                        <Link
                          to={getBlogUrl(
                            site.editorPickBlog.publishedAt,
                            site.editorPickBlog.slug.current,
                          )}
                        >
                          {site.editorPickBlog.title}
                        </Link>
                      </h3>
                      <p className="card-text">
                        {site.editorPickBlog._rawExcerpt && (
                          <PortableText
                            blocks={site.editorPickBlog._rawExcerpt}
                          />
                        )}
                      </p>
                      <p className="card-text d-flex justify-content-between">
                        <small className="text-muted">
                          {format(
                            new Date(site.editorPickBlog.publishedAt),
                            "MMM Mo, yyyy",
                          )}
                        </small>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
        <section className="blog-grid pt-5 pb-5 grey-bg2">
          <div className="container">
            <div className="heading-n-description mb-5 ">
              <h2>Latest Posts</h2>
            </div>
            <div className="row">
              {currentPostData &&
                currentPostData.map((node) => (
                  <BlogPostPreview key={node.id} {...node} isInList />
                ))}
            </div>

            {currentIndex + 1 < postNodes.length && (
              <div className="text-center ">
                <a href="#" className="btn orange" onClick={loadMoreAction}>
                  Load More
                </a>
              </div>
            )}
          </div>
        </section>
        {site?.footerBlog && (
          <section className="last-post-section blog-post-section pt-5 pb-5">
            <div className="container">
              <div className="card mt-3 mb-3">
                <div className="row g-0">
                  <div className="col-lg-7 col-md-7 order-md-2 text-md-end">
                    <SanityImage
                      {...site.footerBlog.mainImage}
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "cover",
                      }}
                      className="img-fluid ps-lg-5"
                      loading="eager"
                    />
                  </div>
                  <div className="col-lg-5 col-md-5 order-md-1 d-flex align-items-center">
                    <div className="card-body">
                      <h3 className="card-title">
                        <Link
                          to={getBlogUrl(
                            site.footerBlog.publishedAt,
                            site.footerBlog.slug.current,
                          )}
                        >
                          {site.footerBlog.title}
                        </Link>
                      </h3>
                      <p className="card-text">
                        {site.footerBlog._rawExcerpt && (
                          <PortableText blocks={site.footerBlog._rawExcerpt} />
                        )}
                      </p>
                      <p className="card-text d-flex justify-content-between">
                        <small className="text-muted">
                          {format(
                            new Date(site.footerBlog.publishedAt),
                            "MMM Mo, yyyy",
                          )}
                        </small>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </div>
    </Layout>
  );
};

export default BlogPage;
